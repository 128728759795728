import { useAuth0 } from '@auth0/auth0-react'
import Nes from '@hapi/nes/lib/client'
import { useEffect } from 'react'
import { queryClient } from 'src'
import { tenMinutes } from '~constants/constants'
import { PatchCargoPayload } from '~hooks/queries/cargo/use-post-patch-delete-cargo'
import { apiBase, webSocketBase } from '~utils/base-url'
import { useFetch } from '~utils/fetch-request'
import {
  constructGetCargoProps,
  orderCargo,
} from '../componenets/cargo-table/cargo-table-section'
import { CargoListPageSearchParams, cargoTableSections } from '../constants'
import { CargoStatus } from '../content/select-n-autocomplete-options'
import { constructGetCargoRequestUrl, GetCargoItem } from './use-get-cargo'

interface Change {
  type: string
  fullDocument: GetCargoItem
  documentKey: { _id: string }
  updatedFields: PatchCargoPayload
}

export function useCargoSubscription() {
  const { getAccessTokenSilently } = useAuth0()
  const fetchRequest = useFetch()

  async function fetchCargo(url: string): Promise<unknown> {
    return fetchRequest(`${apiBase()}${url}`, {
      method: 'GET',
    })
  }

  useEffect(() => {
    const cargoClient = new Nes.Client(
      webSocketBase() as `ws://${string}` | `wss://${string}`,
    )

    const startWebSocket = async () => {
      try {
        const token = await getAccessTokenSilently()

        await cargoClient.connect({
          auth: { headers: { Authorization: token } },
        })

        cargoClient.subscribe('/cargo', (change: unknown) => {
          const data = change as Change

          if (data.type === 'insert' && data.fullDocument) {
            processCargoInsert(data, fetchCargo)
          }

          if (data.type === 'delete' && data.documentKey) {
            processCargoDelete(data)
          }
          if (
            data.type === 'update' &&
            data.documentKey &&
            data.updatedFields
          ) {
            processCargoUpdate(data, fetchCargo)
          }
        })
      } catch (error) {
        console.error('WebSocket connection error:', error)
      }
    }

    startWebSocket()
    const intervalId = setInterval(() => {
      queryClient.invalidateQueries({ queryKey: ['/cargo'] })
    }, tenMinutes)

    return () => {
      clearInterval(intervalId)
      cargoClient.disconnect()
      console.log('WebSocket disconnected')
    }
  }, [getAccessTokenSilently])
}

function getCargoKeys(status?: string) {
  const queryCache = queryClient.getQueryCache().findAll()

  return queryCache
    .map((query) => query.queryKey)
    .filter((key) => {
      if (!Array.isArray(key) || !key[0].includes('/cargo')) return false
      return status ? key[0].includes(status) : true
    })
}

function getFreshDataUrls(status?: CargoStatus): string[] {
  const urlObj = new URL(window.location.href)
  const params = new URLSearchParams(urlObj.search)
  const sectionsWithDocumentStatus = !status
    ? cargoTableSections
    : cargoTableSections.filter((section) => section.statuses.includes(status))

  return sectionsWithDocumentStatus.map((section) => {
    return constructGetCargoRequestUrl({
      ...constructGetCargoProps({
        params,
        statuses: section.statuses,
      }),
    })
  })
}

function invalidateInactiveQueries({
  excludeUrls,
  status,
}: {
  excludeUrls?: string[]
  status?: CargoStatus
} = {}) {
  const cargoKeys = getCargoKeys(status)
  const inactiveCachedDataUrls = !excludeUrls
    ? cargoKeys
    : cargoKeys.filter((key) => excludeUrls.every((url) => url !== key[0]))

  inactiveCachedDataUrls.forEach((queryKey) => {
    queryClient.invalidateQueries({ queryKey })
  })
}

function processCargoInsert(
  data: Change,
  fetchCargo: (url: string) => Promise<unknown>,
) {
  const { fullDocument } = data
  if (!fullDocument) return

  const { status, _id } = fullDocument
  if (!status || !_id) return

  const freshCachedDataUrls = getFreshDataUrls(status)
  invalidateInactiveQueries({ excludeUrls: freshCachedDataUrls, status })

  freshCachedDataUrls.forEach((url) => {
    fetchCargo(`${url}&cargoId=${_id}`).then((res) => {
      if (res && Array.isArray(res) && res.length > 0) {
        queryClient.setQueryData<GetCargoItem[]>([url], (oldData) => {
          return orderData([fullDocument, ...(oldData || [])])
        })
      }
    })
  })
}

function processCargoDelete(data: Change) {
  const { documentKey } = data
  if (!documentKey) return

  const freshCachedDataUrls = getFreshDataUrls()
  invalidateInactiveQueries({ excludeUrls: freshCachedDataUrls })

  freshCachedDataUrls.forEach((url) => {
    queryClient.setQueryData<GetCargoItem[]>([url], (oldData) => {
      return oldData?.filter((cargo) => cargo._id !== documentKey._id)
    })
  })
}

function processCargoUpdate(
  data: Change,
  fetchCargo: (url: string) => Promise<unknown>,
) {
  const { documentKey, updatedFields } = data
  if (!documentKey || !updatedFields) return

  const singleCargoUrl = `/cargo/${documentKey._id}`
  const freshCachedDataUrls = getFreshDataUrls()
  const cargoKeys = getCargoKeys()

  const isInCache = cargoKeys.some(
    (key) => Array.isArray(key) && key[0].includes(documentKey._id),
  )

  if (isInCache) {
    queryClient.setQueryData<GetCargoItem>([singleCargoUrl], (oldData) => {
      return {
        ...oldData,
        ...updatedFields,
      } as GetCargoItem
    })
  }

  if (updatedFields.status) {
    // if status is updated, cargo should be removed from the old section
    // and added to the new section
    processCargoDelete(data)
    processCargoInsert(data, fetchCargo)
    return
  }

  freshCachedDataUrls.forEach((url) => {
    queryClient.setQueryData<GetCargoItem[]>([url], (oldData) => {
      const updatedItem = oldData?.find((item) => item._id === documentKey._id)

      const updatedData = oldData?.map((item) =>
        item._id === documentKey._id
          ? { ...updatedItem, ...updatedFields }
          : item,
      ) as GetCargoItem[]

      return orderData(updatedData)
    })
  })

  invalidateInactiveQueries({
    excludeUrls: [...freshCachedDataUrls, singleCargoUrl],
  })
}

function orderData(data: GetCargoItem[]) {
  const urlObj = new URL(window.location.href)
  const params = new URLSearchParams(urlObj.search)
  const isAscendingOrder =
    params.get(CargoListPageSearchParams.orderByDate) === 'asc'

  return orderCargo(data, isAscendingOrder ? 'asc' : 'desc')
}
