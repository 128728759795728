import { useQuery } from '@tanstack/react-query'
import { apiBase } from '~utils/base-url'
import { useFetch } from '~utils/fetch-request'
import { GetCargoItem } from './use-get-cargo'

export function useGetSingleCargo(cargoId?: string | null) {
  const fetchFn = useFetch<GetCargoItem>()
  const url = `/cargo/${cargoId}`

  return useQuery({
    queryKey: [url],
    queryFn: () => fetchFn(`${apiBase()}${url}`),
    enabled: !!cargoId,
  })
}
