import { Loader } from '@maersktankersdigital/web-components'
import { useSearchParams } from 'react-router-dom'
import { Modal } from '~components/molecules/modal/modal'
import { CargoListPageSearchParams } from '../../constants'
import { useGetSingleCargo } from '../../hooks/use-get-single-cargo'
import { CargoForm } from '../cargo-form'

const disabledFields = [
  '_id',
  'broker',
  'cargo',
  'cargoGrade',
  'charterer',
  'commercialOperator',
  'comments',
  'commentsCreatedAt',
  'commentsCreatedBy',
  'cpDate',
  'createdAt',
  'createdBy',
  'dateQuoted',
  'dischargePort',
  'dischargeWorldArea',
  'euEts',
  'failedInstances',
  'hireDetails',
  'history',
  'isMtFleet',
  'isCommercialManagement',
  'laycan',
  'laycanFrom',
  'laycanTo',
  'loadPort',
  'loadWorldArea',
  'owner',
  'periodDetails',
  'periodTcOut',
  'periodTcOutType',
  'pic',
  'pool',
  'privateAndConfidential',
  'quantity',
  'quantityType',
  'rate',
  'rateHire',
  'rateHireType',
  'reminderDate',
  'status',
  'subsDueTime',
  'tradingType',
  'tradingWorldArea',
  'updatedAt',
  'updatedBy',
  'vessel',
  'worldArea',
]

export function ReadCargoModal({ onClose }: { onClose: () => void }) {
  const [searchParams] = useSearchParams()
  const cargoId = searchParams.get(CargoListPageSearchParams.readCargo)
  const { data: existingCargo, isLoading } = useGetSingleCargo(cargoId)

  if (isLoading || !existingCargo) {
    return (
      <Modal open title="Loading cargo" onClose={onClose}>
        <Loader />
      </Modal>
    )
  }

  return (
    <Modal variant="wide" open title="Cargo" onClose={onClose}>
      <CargoForm
        // @ts-ignore
        formInput={existingCargo}
        updateFormInput={() => {}}
        missingFields={[]}
        disabledFields={disabledFields}
      />
    </Modal>
  )
}
