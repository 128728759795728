import { DatePicker, TextButton } from '@maersktankersdigital/web-components'
import { Box, FormControlLabel, Switch, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { format } from 'date-fns'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Tooltip } from '~components/tooltip'
import { useGetMe } from '~hooks/queries/me/use-get-me'
import { usePatchMeVT } from '~hooks/queries/me/use-patch-me-vt'
import {
  CargoListPageSearchParams,
  dateFormat,
  defaultFilters,
} from '../constants'
import {
  cargoGradeOptions,
  productCodesWithCargoGrades,
} from '../content/select-n-autocomplete-options'
import { AddCargoButton } from './add-cargo-button'
import { Type } from './cargo-modal/add-cargo-modal'
import { AreaRenderer, MultiItemAutocomplete, PicAutocomplete } from './fields'

export interface ICargoFilters {
  [CargoListPageSearchParams.cargoGrade]?: string[]
  [CargoListPageSearchParams.pools]?: string[]
  [CargoListPageSearchParams.worldArea]?: string[]
  [CargoListPageSearchParams.limitFrom]?: string
  [CargoListPageSearchParams.limitTo]?: string
  [CargoListPageSearchParams.cargoType]?: Type[]
  [CargoListPageSearchParams.pic]?: string[]
}

function constructPayload(filters?: ICargoFilters) {
  if (!filters) {
    return {
      settings: {
        cargoList: null,
      },
    }
  }

  const appliedFilters: ICargoFilters = Object.keys(defaultFilters).reduce(
    (acc, key) => ({
      ...acc,
      [key]:
        filters[key as keyof ICargoFilters] ||
        defaultFilters[key as keyof ICargoFilters],
    }),
    {},
  )

  delete appliedFilters[CargoListPageSearchParams.limitFrom]
  delete appliedFilters[CargoListPageSearchParams.limitTo]

  return {
    settings: {
      cargoList: {
        filters: appliedFilters,
      },
    },
  }
}

export function CargoFilters() {
  const { data } = useGetMe()
  const { mutateAsync: patchMe } = usePatchMeVT()
  const [searchParams, setSearchParams] = useSearchParams()
  const poolsParam = searchParams.getAll(CargoListPageSearchParams.pools)
  const worldAreaParam = searchParams.getAll(
    CargoListPageSearchParams.worldArea,
  )
  const cargoGradeParam = searchParams.getAll(
    CargoListPageSearchParams.cargoGrade,
  )
  const limitFromParam = searchParams.get(CargoListPageSearchParams.limitFrom)
  const limitToParam = searchParams.get(CargoListPageSearchParams.limitTo)
  const cargoTypeParam = searchParams.getAll(
    CargoListPageSearchParams.cargoType,
  )
  const picParam = searchParams.getAll(CargoListPageSearchParams.pic)

  useEffect(() => {
    if (!data?.settings?.cargoList?.filters) return

    const filters = {
      ...defaultFilters,
      ...data?.settings?.cargoList?.filters,
    } as any

    setSearchParams(filters)
  }, [data])

  function onChangeHandler(values: ICargoFilters) {
    const previous = {
      [CargoListPageSearchParams.cargoGrade]:
        cargoGradeParam || defaultFilters[CargoListPageSearchParams.cargoGrade],
      [CargoListPageSearchParams.pools]:
        poolsParam || defaultFilters[CargoListPageSearchParams.pools],
      [CargoListPageSearchParams.worldArea]:
        worldAreaParam || defaultFilters[CargoListPageSearchParams.worldArea],
      [CargoListPageSearchParams.limitFrom]:
        limitFromParam || defaultFilters[CargoListPageSearchParams.limitFrom],
      [CargoListPageSearchParams.limitTo]:
        limitToParam || defaultFilters[CargoListPageSearchParams.limitTo],
      [CargoListPageSearchParams.cargoType]:
        cargoTypeParam || defaultFilters[CargoListPageSearchParams.cargoType],
      [CargoListPageSearchParams.pic]:
        picParam || defaultFilters[CargoListPageSearchParams.pic],
    }

    const updatedFilters = { ...previous, ...values } as any

    setSearchParams(updatedFilters)
    patchMe(constructPayload(updatedFilters))
  }

  function resetFilters() {
    setSearchParams()
    patchMe(constructPayload())
  }

  return (
    <Grid
      container
      spacing={4}
      sx={{
        alignItems: 'end',
        '.react-datepicker__input-container': { input: { width: '100%' } },
        '.react-datepicker__input-container > *': { width: '100%' },
      }}
    >
      <Grid size={{ xs: 12, md: 6, xl: 3, xxl: 2 }}>
        <AreaRenderer
          disableInputValidation
          input={worldAreaParam}
          onChangeHandler={(worldArea) =>
            onChangeHandler({
              [CargoListPageSearchParams.worldArea]: worldArea,
            })
          }
          label="Load/Delivery World Area"
        />
      </Grid>

      <Grid size={{ xs: 12, md: 6, xl: 3, xxl: 2 }}>
        <MultiItemAutocomplete
          input={poolsParam}
          label="Pool(s)"
          onChangeHandler={(e, pools) =>
            onChangeHandler({
              [CargoListPageSearchParams.pools]: pools,
            })
          }
          options={data?.activePools || []}
        />
      </Grid>

      <Grid size={{ xs: 12, md: 6, xl: 3, xxl: 2 }}>
        <MultiItemAutocomplete
          label="Cargo Grade"
          input={cargoGradeParam}
          onChangeHandler={(e, cargoGrade) =>
            onChangeHandler({
              [CargoListPageSearchParams.cargoGrade]: cargoGrade,
            })
          }
          options={[...cargoGradeOptions, ...productCodesWithCargoGrades]}
        />
      </Grid>

      <Grid size={{ xs: 12, md: 6, xl: 3, xxl: 2 }}>
        <PicAutocomplete
          formInput={{ pic: picParam ? picParam[0] : '' }}
          updateFormInput={({ pic }) => {
            onChangeHandler({
              [CargoListPageSearchParams.pic]: pic ? [pic] : [],
            })
          }}
          label="Person in charge (PIC)"
        />
      </Grid>

      <Grid size={{ xs: 12, md: 6, xl: 3, xxl: 2 }}>
        <Box sx={{ display: 'flex', gap: 2, pb: 2 }}>
          <Typography variant="label" component="p">
            Quotes from
          </Typography>
          <Tooltip title="Date filter only applies to quotes with status Fixed, Failed or Withdrawn" />
        </Box>
        <DatePicker
          name="Quotes from"
          onChange={(date) => {
            onChangeHandler({
              [CargoListPageSearchParams.limitFrom]: date
                ? format(date, dateFormat)
                : undefined,
            })
          }}
          value={
            limitFromParam ||
            defaultFilters[CargoListPageSearchParams.limitFrom]
          }
        />
      </Grid>

      <Grid size={{ xs: 12, md: 6, xl: 3, xxl: 2 }}>
        <Box sx={{ display: 'flex', gap: 2, pb: 2 }}>
          <Typography variant="label" component="p">
            Quotes to
          </Typography>
          <Tooltip title="Date filter only applies to quotes with status Fixed, Failed or Withdrawn" />
        </Box>
        <DatePicker
          name="Quotes to"
          onChange={(date) => {
            onChangeHandler({
              [CargoListPageSearchParams.limitTo]: date
                ? format(date, dateFormat)
                : undefined,
            })
          }}
          value={
            limitToParam || defaultFilters[CargoListPageSearchParams.limitTo]
          }
        />
      </Grid>

      <Grid size={{ xs: 12, md: 6, xl: 4, xxl: 4 }}>
        <Box
          sx={{
            display: 'flex',
            rowGap: 2,
            columnGap: 4,
            flexWrap: 'wrap',
            mb: 3,
          }}
        >
          <FormControlLabel
            control={
              <Switch
                name="toggle_spot"
                checked={
                  !!cargoTypeParam.length
                    ? cargoTypeParam
                        .map((type) => type.toLowerCase())
                        .includes('spot')
                    : true
                }
                onChange={(e) =>
                  onChangeHandler({
                    [CargoListPageSearchParams.cargoType]: e.target.checked
                      ? ([...cargoTypeParam, 'SPOT'] as Type[])
                      : ['T/C'],
                  })
                }
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            sx={{
              '& .MuiFormControlLabel-label': {
                fontSize: { xs: '0.875rem', xl: '1rem' },
              },
            }}
            label="Show Spot"
          />
          <FormControlLabel
            control={
              <Switch
                name="toggle_tc"
                checked={
                  !!cargoTypeParam.length
                    ? cargoTypeParam
                        .map((type) => type.toLowerCase())
                        .includes('t/c')
                    : true
                }
                onChange={(e) =>
                  onChangeHandler({
                    [CargoListPageSearchParams.cargoType]: e.target.checked
                      ? ([...cargoTypeParam, 'T/C'] as Type[])
                      : ['SPOT'],
                  })
                }
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            sx={{
              '& .MuiFormControlLabel-label': {
                fontSize: { xs: '0.875rem', xl: '1rem' },
              },
            }}
            label="Show T/C"
          />
        </Box>
      </Grid>
      <Grid size={{ xs: 12, md: 6, xl: 3, xxl: 2 }}>
        <Box component={TextButton} sx={{ p: 0, mb: 3 }} onClick={resetFilters}>
          Reset filters
        </Box>
      </Grid>
      <Grid size={{ xs: 12, md: 6, xl: 2 }}>
        <AddCargoButton />
      </Grid>
    </Grid>
  )
}
