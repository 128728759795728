import {
  Button,
  Loader,
  TextButton,
} from '@maersktankersdigital/web-components'
import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import ConfettiExplosion from 'react-confetti-explosion'
import { useSearchParams } from 'react-router-dom'
import { useAnimatedAlert } from '~components/molecules/animated-alert/animated-alert-provider'
import ConfirmModal from '~components/molecules/modal/confirm-modal/confirm-modal'
import { Modal } from '~components/molecules/modal/modal'
import {
  PatchCargoPayload,
  PostCargoPayload,
  useDeleteCargo,
  usePatchCargo,
} from '~hooks/queries/cargo/use-post-patch-delete-cargo'
import { CargoListPageSearchParams } from '../../constants'
import { CargoStatus } from '../../content/select-n-autocomplete-options'
import { missingRequired, notFound } from '../../content/toast-content'
import { useGetSingleCargo } from '../../hooks/use-get-single-cargo'
import { CargoForm } from '../cargo-form'
import {
  getMissingRequiredFields,
  getRequiredKeys,
} from '../utils/required-fields-check'
import { showAnimatedAlert } from '../utils/show-animated-alert'
import { FormInput } from './add-cargo-modal'

export function UpdateCargoModal({ onClose }: { onClose: () => void }) {
  const [searchParams, setSearchParams] = useSearchParams()
  const cargoId = searchParams.get(CargoListPageSearchParams.editCargo)
  const { setStatus } = useAnimatedAlert()
  const { data: existingCargo, isLoading, isError } = useGetSingleCargo(cargoId)
  const { mutate: deleteCargo, status: deleteStatus } = useDeleteCargo()
  const {
    mutate: patchCargo,
    status: patchStatus,
    data: patchedData,
  } = usePatchCargo()
  const [isOpen, setIsOpen] = useState(true)
  const [isExploding, setIsExploding] = useState(false)
  const [formInput, setFormInput] = useState<FormInput>()
  const [updatedInput, setUpdatedInput] = useState<PatchCargoPayload>()
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
  const [showFailCargoDialogue, setShowFailCargoDialogue] = useState(false)
  const [recreateCargo, setRecreateCargo] = useState(false)
  const [missingFields, setMissingFields] = useState<(keyof FormInput)[]>()

  useEffect(() => {
    if (isLoading) return

    if ((!existingCargo && deleteStatus === 'idle') || isError) {
      setStatus(notFound, undefined, 'error')
      onClose()
      return
    }

    setFormInput(existingCargo as FormInput)
  }, [existingCargo, cargoId])

  useEffect(() => {
    const handleStatus = (status: string, action: 'patch' | 'delete') => {
      showAnimatedAlert({
        setStatus,
        status,
        action,
        data: patchedData,
      })

      if (status === 'success') {
        if (recreateCargo) {
          if (!existingCargo) {
            setStatus(notFound, undefined, 'error')
            onClose()
            return
          }

          searchParams.delete(CargoListPageSearchParams.editCargo)
          searchParams.set(
            CargoListPageSearchParams.recreateCargo,
            existingCargo._id,
          )
          setSearchParams(searchParams)
          return
        }

        if (
          patchedData?.status === CargoStatus.Fixed &&
          patchedData?.isMtFleet
        ) {
          setIsOpen(false)
          setIsExploding(true)
          return
        }

        onClose()
      }
    }

    handleStatus(patchStatus, 'patch')
    handleStatus(deleteStatus, 'delete')
  }, [patchStatus, deleteStatus, patchedData])

  function updateFormInput(input: FormInput) {
    if (!existingCargo) return

    setFormInput({ ...formInput, ...input })

    setUpdatedInput((prev) =>
      !prev ? { ...input, _id: existingCargo._id } : { ...prev, ...input },
    )
  }

  function updateClickHandler() {
    const missingRequiredFields = getMissingRequiredFields(formInput || {})

    if (missingRequiredFields.length > 0) {
      setStatus(missingRequired, undefined, 'error')
      setMissingFields(missingRequiredFields)
      return
    }

    setMissingFields(undefined)

    return updatedInput?.status === CargoStatus.Failed
      ? setShowFailCargoDialogue(true)
      : onUpdateCargo()
  }

  function onUpdateCargo() {
    if (!updatedInput || !existingCargo) {
      onClose()
      return
    }

    const sanitizedInput = Object.fromEntries(
      Object.entries(updatedInput).map(([key, value]) => [
        key,
        value === undefined || value === '' ? null : value, // Convert empty strings and undefined to null as required by the API
      ]),
    )

    const isValid = Object.entries(sanitizedInput).every(([key, value]) => {
      if (
        !getRequiredKeys(formInput?.tradingType, formInput?.status).includes(
          key as keyof PostCargoPayload,
        )
      )
        return true

      return value !== null &&
        value !== undefined &&
        value !== '' &&
        Array.isArray(value)
        ? value.length > 0
        : true
    })

    if (!isValid) {
      setStatus(missingRequired, undefined, 'error')
      return
    }

    patchCargo({ ...sanitizedInput, _id: existingCargo._id })
  }

  function deleteCargoHandler() {
    if (!existingCargo) {
      setStatus(notFound, undefined, 'error')
      return
    }
    deleteCargo(existingCargo._id)
  }

  if (isLoading || !formInput) {
    return (
      <Modal open title="Loading cargo" onClose={onClose}>
        <Loader />
      </Modal>
    )
  }

  return (
    <>
      {isExploding && (
        <Box
          sx={{
            position: 'fixed',
            width: '100%',
            height: '100vh',
            zIndex: 99999,
          }}
        >
          <ConfettiExplosion
            particleCount={250}
            force={0.8}
            onComplete={() => {
              setIsExploding(false)
              onClose()
            }}
            zIndex={99999}
          />
        </Box>
      )}

      <Modal
        variant="wide"
        open={isOpen}
        title="Update cargo"
        onClose={onClose}
      >
        <>
          <CargoForm
            formInput={formInput}
            updateFormInput={updateFormInput}
            missingFields={missingFields}
          />
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button onClick={updateClickHandler}>Update</Button>
            <TextButton onClick={onClose}>Cancel</TextButton>
            {existingCargo && (
              <Box
                component={TextButton}
                sx={{ ml: 'auto' }}
                onClick={() => setShowDeleteConfirm(true)}
                variant="warning"
              >
                Delete cargo
              </Box>
            )}
          </Box>
        </>
      </Modal>
      <ConfirmModal
        header="Are you sure you want to delete this cargo?"
        isVisible={showDeleteConfirm}
        onConfirm={deleteCargoHandler}
        onCancel={() => setShowDeleteConfirm(false)}
        onClose={() => setShowDeleteConfirm(false)}
      />
      <FailCargoConfirmation
        isVisible={showFailCargoDialogue}
        onConfirm={() => {
          setRecreateCargo(true)
          onUpdateCargo()
        }}
        onCancel={() => {
          setRecreateCargo(false)
          onUpdateCargo()
        }}
        onClose={() => setShowFailCargoDialogue(false)}
      />
    </>
  )
}

export function FailCargoConfirmation({
  isVisible,
  onConfirm,
  onCancel,
  onClose,
}: {
  isVisible: boolean
  onConfirm: () => void
  onCancel: () => void
  onClose: () => void
}) {
  return (
    <ConfirmModal
      header="You are about to fail this cargo"
      content="Would you like to recreate it too?"
      isVisible={isVisible}
      onConfirm={onConfirm}
      confirmButtonText="Yes, recreate it too"
      onCancel={onCancel}
      cancelButtonText="No, just fail it"
      onClose={onClose}
    />
  )
}
