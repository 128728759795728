import { Alert, Loader } from '@maersktankersdigital/web-components'
import { Divider, Typography } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import {
  CargoListPageSearchParams,
  defaultFilters,
  statusesNeedingPeriodFilter,
} from '../../constants'
import { CargoStatus } from '../../content/select-n-autocomplete-options'
import {
  GetCargoItem,
  GetCargoParams,
  useGetCargo,
} from '../../hooks/use-get-cargo'
import { CargoTableBody } from './cargo-table-body'
import { CargoTableHead } from './cargo-table-head'
import { SingleCellTableBody } from './single-cell-table-body'

interface CargoStatusTableProps {
  statuses: CargoStatus[]
  title: string
}

export function CargoTableSection({ statuses, title }: CargoStatusTableProps) {
  const { data, isLoading, isError } = useGetFilteredCargo(statuses)

  return (
    <>
      <SingleCellTableBody>
        <Typography sx={{ pl: 2, pb: 1, pt: 5 }}>
          {`${title} ${data?.length ? `(${data?.length})` : ''}`}
        </Typography>
        <Divider />
      </SingleCellTableBody>
      {isLoading ? (
        <SingleCellTableBody>
          <Loader />
        </SingleCellTableBody>
      ) : isError ? (
        <SingleCellTableBody>
          <Alert variant="error" text="There was an error getting cargo" />
        </SingleCellTableBody>
      ) : !data || data.length === 0 ? (
        <SingleCellTableBody>
          <Alert
            variant="info"
            text="There is no cargo with this status. Try to set different filters."
          />
        </SingleCellTableBody>
      ) : (
        <>
          <CargoTableHead />
          <CargoTableBody data={data} />
        </>
      )}
    </>
  )
}

export function orderCargo(data: GetCargoItem[], order: 'asc' | 'desc') {
  data.sort((a, b) => {
    const dateA = new Date(a.dateQuoted ?? 0).getTime()
    const dateB = new Date(b.dateQuoted ?? 0).getTime()

    return order === 'asc' ? dateA - dateB : dateB - dateA
  })
  return data
}

function useGetFilteredCargo(statuses: CargoStatus[]) {
  const [searchParams] = useSearchParams()
  const isAscendingOrder =
    searchParams.get(CargoListPageSearchParams.orderByDate) === 'asc'

  const { data, isLoading, isError } = useGetCargo(
    constructGetCargoProps({ params: searchParams, statuses }),
  )

  if (data) {
    orderCargo(data, isAscendingOrder ? 'asc' : 'desc')
  }

  return {
    data,
    isLoading: isLoading || (!isError && !data),
    isError,
  }
}

export function constructGetCargoProps({
  params,
  statuses,
}: {
  params: URLSearchParams
  statuses: CargoStatus[]
}) {
  const worldAreaParam = params.getAll(CargoListPageSearchParams.worldArea)
  const poolsParam = params.getAll(CargoListPageSearchParams.pools)
  const cargoGradeParam = params.getAll(CargoListPageSearchParams.cargoGrade)
  const limitFromParam = params.get(CargoListPageSearchParams.limitFrom)
  const limitToParam = params.get(CargoListPageSearchParams.limitTo)
  const cargoTypeParam = params.getAll(CargoListPageSearchParams.cargoType)
  const picParam = params.getAll(CargoListPageSearchParams.pic)

  const props: GetCargoParams = {
    cargoGrades: cargoGradeParam,
    loadWorldAreas: worldAreaParam,
    pics: picParam,
    pools: poolsParam,
    statuses,
    tradingTypes: cargoTypeParam,
  }

  if (statuses.some((status) => statusesNeedingPeriodFilter.includes(status))) {
    props.limitFrom =
      limitFromParam || defaultFilters[CargoListPageSearchParams.limitFrom]
    props.limitTo =
      limitToParam || defaultFilters[CargoListPageSearchParams.limitTo]
  }

  return props
}
