import {
  Button,
  Loader,
  TextButton,
} from '@maersktankersdigital/web-components'
import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useAnimatedAlert } from '~components/molecules/animated-alert/animated-alert-provider'
import { Modal } from '~components/molecules/modal/modal'
import {
  PostCargoPayload,
  usePostCargo,
} from '~hooks/queries/cargo/use-post-patch-delete-cargo'
import { useGetMe } from '~hooks/queries/me/use-get-me'
import { CargoListPageSearchParams } from '../../constants'
import { CargoStatus } from '../../content/select-n-autocomplete-options'
import {
  apiStatusMessages,
  missingRequired,
  notFound,
} from '../../content/toast-content'
import { GetCargoItem } from '../../hooks/use-get-cargo'
import { useGetSingleCargo } from '../../hooks/use-get-single-cargo'
import { CargoForm } from '../cargo-form'
import { getMissingRequiredFields } from '../utils/required-fields-check'
import { FormInput } from './add-cargo-modal'

const keysToDeleteFromCargoCopy: (keyof GetCargoItem)[] = [
  '_id',
  'commercialOperator',
  'commentsCreatedAt',
  'commentsCreatedBy',
  'cpDate',
  'createdAt',
  'createdBy',
  'hireDetails',
  'history',
  'isCommercialManagement',
  'isMtFleet',
  'rate',
  'rateHire',
  'rateHireType',
  'subsDueTime',
  'updatedAt',
  'updatedBy',
  'vessel',
]

export function RecreateCargoModal({ onClose }: { onClose: () => void }) {
  const [searchParams] = useSearchParams()
  const { data: me, isLoading: isLoadingMe } = useGetMe()
  const cargoId = searchParams.get(CargoListPageSearchParams.recreateCargo)
  const { setStatus } = useAnimatedAlert()
  const { data: existingCargo, isLoading: isLoadingCargo } =
    useGetSingleCargo(cargoId)
  const { mutate: postCargo, status: postStatus } = usePostCargo()
  const [formInput, setFormInput] = useState<FormInput>()
  const [missingFields, setMissingFields] = useState<(keyof FormInput)[]>()

  useEffect(() => {
    if (isLoadingCargo || isLoadingMe) return

    if (!existingCargo || !cargoId) {
      setStatus(notFound, undefined, 'error')
      onClose()
      return
    }

    const cargoCopy: GetCargoItem = {
      ...existingCargo,
      failedInstances: existingCargo.failedInstances
        ? [...existingCargo.failedInstances, cargoId]
        : [cargoId],
    }

    keysToDeleteFromCargoCopy.forEach((key) => delete cargoCopy[key])
    cargoCopy.status = CargoStatus.Open
    cargoCopy.pic = me?.name || ''

    setFormInput(cargoCopy as FormInput)
  }, [existingCargo, isLoadingCargo, isLoadingMe, me])

  useEffect(() => {
    const handleStatus = (status: string, action: 'post') => {
      if (status === 'success' || status === 'error') {
        setStatus(apiStatusMessages[status][action], undefined, status)

        if (status === 'success') onClose()
      }
    }

    handleStatus(postStatus, 'post')
  }, [postStatus])

  function updateFormInput(input: FormInput) {
    setFormInput({ ...formInput, ...input })
  }

  function onAddHandler() {
    if (!formInput) {
      onClose()
      return
    }

    const missingRequiredFields = getMissingRequiredFields(formInput)
    if (missingRequiredFields.length > 0) {
      setStatus(missingRequired, undefined, 'error')
      setMissingFields(missingRequiredFields)
      return
    }

    setMissingFields(undefined)

    postCargo(formInput as PostCargoPayload)
  }

  if (isLoadingCargo || isLoadingMe || !formInput) {
    return (
      <Modal open title="Loading cargo" onClose={onClose}>
        <Loader />
      </Modal>
    )
  }

  return (
    <>
      <Modal variant="wide" open title="Recreate cargo" onClose={onClose}>
        <>
          <CargoForm
            formInput={formInput}
            updateFormInput={updateFormInput}
            missingFields={missingFields}
          />
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button onClick={onAddHandler}>Recreate</Button>
            <TextButton onClick={onClose}>Cancel</TextButton>
          </Box>
        </>
      </Modal>
    </>
  )
}
