import { useQuery } from '@tanstack/react-query'
import { format, parse } from 'date-fns'
import { apiBase } from '~utils/base-url'
import { useFetch } from '~utils/fetch-request'
import { Type } from '../componenets/cargo-modal/add-cargo-modal'
import { dateFormat } from '../constants'
import { CargoStatus } from '../content/select-n-autocomplete-options'

export interface GetCargoParams {
  cargoGrades?: string[] | null
  cargoId?: string | null
  limitFrom?: string | null
  limitTo?: string | null
  loadWorldAreas?: string[] | null
  pics?: string[] | null
  pools?: string[] | null
  statuses?: CargoStatus[] | null
  tradingTypes?: string[] | null
}

const dateFormatAcceptedByApi = 'yyyy-MM-dd'

export function useGetCargo(props: GetCargoParams = {}) {
  const fetchFn = useFetch<GetCargoItem[]>()
  const url = constructGetCargoRequestUrl(props)
  return useQuery({
    queryKey: [url],
    queryFn: () => fetchFn(`${apiBase()}${url}`),
  })
}

export function constructGetCargoRequestUrl({
  cargoGrades,
  cargoId,
  limitFrom,
  limitTo,
  loadWorldAreas,
  pics,
  pools,
  statuses,
  tradingTypes,
}: GetCargoParams) {
  let url = '/cargo'
  const params = new URLSearchParams()
  const appendArrayParams = (key: string, values?: string[] | null) => {
    if (values?.length) {
      values.forEach((value) => params.append(key, value))
    }
  }

  appendArrayParams('cargoGrade', cargoGrades)
  appendArrayParams('loadWorldArea', loadWorldAreas)
  appendArrayParams('pic', pics)
  appendArrayParams('pool', pools)
  appendArrayParams('status', statuses)
  appendArrayParams('tradingType', tradingTypes)

  if (cargoId) {
    params.append('cargoId', cargoId)
  }
  if (limitFrom) {
    params.append('limitFrom', formatDate(limitFrom))
  }
  if (limitTo) {
    params.append('limitTo', formatDate(limitTo))
  }

  if (params.toString()) {
    url = `${url}?${params.toString()}`
  }
  return url
}

export interface GetCargoItem {
  _id: string
  broker?: string
  cargo?: string
  cargoGrade?: string[]
  charterer: string
  commercialOperator?: string
  comments?: string
  commentsCreatedAt?: string
  commentsCreatedBy?: string
  cpDate?: string
  createdAt: string
  createdBy: string
  dateQuoted?: string
  dischargePort: string[]
  dischargeWorldArea?: string[]
  euEts?: string
  failedInstances?: string[]
  hireDetails?: string
  history?: History[]
  isMtFleet?: boolean
  isCommercialManagement?: boolean
  laycan?: string
  laycanFrom?: string
  laycanTo?: string
  loadPort: string[]
  loadWorldArea?: string[]
  owner?: string
  periodDetails?: string
  periodTcOut?: number
  periodTcOutType?: string
  pic?: string
  pool: string | string[]
  privateAndConfidential?: boolean
  quantity?: number | string
  quantityType?: string
  rate?: string
  rateHire?: string
  rateHireType?: string
  reminderDate?: string
  status: CargoStatus
  subsDueTime?: string
  tradingType?: Type
  tradingWorldArea?: string[]
  updatedAt?: string
  updatedBy?: string
  vessel?: string
  worldArea?: string
}

interface History {
  tradingType?: string
  privateAndConfidential?: boolean
  laycanTo?: Date
  dateQuoted?: Date
  pic?: string
  status: CargoStatus
  subsDueTime?: Date
  laycanFrom?: Date
  cargoGrade?: string
  quantity?: number
  quantityType?: string
  loadPort?: string[] | string
  loadWorldArea?: string[]
  dischargePort?: string[] | string
  dischargeWorldArea?: string[]
  broker?: string
  charterer: string
  vessel?: string
  commercialOperator?: string
  rateHire?: string
  rateHireType?: string
  cpDate?: Date
  comments?: string
  pool?: string
  createdBy?: string
  createdAt?: Date
  tradingWorldArea?: string[]
  hireDetails?: string
  periodTcOut?: number
  periodTcOutType?: string
  periodDetails?: string
  cargo?: string
  updatedBy?: string
  updatedAt?: Date
  laycan?: Date
  worldArea?: string
}

function formatDate(date: string) {
  const parsedDate = parse(date, dateFormat, new Date())
  return format(parsedDate, dateFormatAcceptedByApi)
}
